//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    TInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    XModal: () => import("@/components/XModal"),
  },
  data() {
    return {
      btnGroupPop: [false, false, false, false], // pop 确认批量删除 批量激活 批量禁用
      dropValue1: "",
      dropValue2: "全部的",
      dropValue3: [], // 选择时间范围

      oldSearchValue: "",
      searchValue: this.TInfo.dropDown.searchInput.value ?? "",
    };
  },
  watch: {
    // 时间范围选择监听，控制只两值
    dropValue3(value, oldvalue) {
      if (!value)
        // null时不执行
        return false;
      if (value.length >= 3) this.dropValue3 = oldvalue;
    },
    // 查询请求细节
    searchValue(val, oldval) {
      this.oldSearchValue = oldval;
    },
  },
  methods: {
    //清空搜索框
    searchValueInit() {
      this.searchValue = "";
    },
    // 按钮组被单机时 分支写这把，不然父组件还是要写分支，看着不简洁
    btnGroupClick(txt) {
      if (txt === "add") {
        this.$emit("addClick"); // 添加不需要弹层确认
      } else if (txt === "start") {
        this.popCalc(1);
      } else if (txt === "close") {
        this.popCalc(2);
      } else if (txt === "del") {
        this.popCalc(3);
      }
    },
    // 后三个按钮有二次确认,先打开个弹层确认，确认后才触发父方法，如果你还要确认，那继续去父组件判断
    popCalc(n) {
      // pop 确认批量删除 批量激活 批量禁用
      this.btnGroupPop.forEach((item, index) => {
        if (index === n) {
          this.btnGroupPop[index] = true;
        } else {
          this.btnGroupPop[index] = false;
        }
      });
      // 单机的那个btn唤醒，别的btn全沉睡
      // 数据处理好后就可以打开XModal弹层了
      this.$forceUpdate();
      this.$modal.show("cPopTxt");
    },
    // 弹层必须选择确认，才开始执行以下事件，传给父级
    handleConfirm() {
      if (this.btnGroupPop[1]) {
        this.$emit("startClick");
      } else if (this.btnGroupPop[2]) {
        this.$emit("closeClick");
      } else if (this.btnGroupPop[3]) {
        this.$emit("delClick");
      }

      // this.$refs.cPopTxt.loadBtn(true)
      // // 这post请求
      // this.$refs.cPopTxt.loadBtn(false)
      this.$modal.hide("cPopTxt");
    },

    // 搜索按钮失去焦点时：空值就别触发查询
    searchEvent() {
      if (this.oldSearchValue === "" && this.searchValue === "") {
        return false;
      }
      this.oldSearchValue = "";
      this.$emit("searchClick", this.searchValue);
    },
    // 搜索按钮被单机时：无脑查询不管有没有值
    searchClick() {
      this.oldSearchValue = "";
      this.$emit("searchClick", this.searchValue);
    },
    // 各个下拉被选中时
    drop1Change() {
      this.$emit("drop1Change", this.dropValue1);
    },
    drop2Change() {
      this.$emit("drop2Change", this.dropValue2);
    },
    drop3Change() {
      // 点击确定按钮后才会触发这货
      if (!this.dropValue3) {
        // null时
        this.$emit("drop3Change", []);
      } else {
        // 快速比较大小：t1.replace(/-/g, '') > t2.replace(/-/g, '')
        this.$emit("drop3Change", this.dropValue3);
      }
    },

    // 节假管理：一键获取国家法定节假，提供
    getVacation() {
      this.$emit("vacationClick");
    },
    toggleSearch() {
      this.showMobileSearch = !this.showMobileSearch;
    },
    back() {
      this.mobile_ToggleState({
        act: "all",
        value: true,
      });
    },
  },
};
